body {
    font-family: 'inter';
  }
  
  .texthead{
    font-family: 'inter';
    font-weight: bolder;
    color:#d6913d ;
  }
  .bg-light {
    background-color: #f8f9fa;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
  }
  
  .p-5 {
    padding: 2rem !important;
  }
  
  h3, h5 {
    color: #333;
  }
  
  h6 {
    font-weight: bold;
  }
  
  .container-fluid {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  
  .image-container img {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .image-container img:hover {
    transform: scale(1.02);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  }
  .image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .responsive-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures image scales properly */
    object-position: center; /* Centers the important  of the image */
  }
  
  @media (max-width: 768px) {
    .responsive-image {
      object-fit: contain; /* Adjusts for smaller screens */
    }
  }
  
  @media (max-width: 576px) {
    .responsive-image {
      object-fit: cover;
      object-position: top; /* Ensures important content is visible */
    }
  }
  