.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-text {
  font-size: 2rem;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: #333;
  display: inline-block;
}

.loading-text span {
  opacity: 0;
  animation: fadeInText 0.3s infinite forwards;
}

@keyframes fadeInText {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
