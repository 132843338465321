body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  background: rgb(234, 225, 225) !important;
  color: black !important;
  letter-spacing: 1px;
  font-family: "Quicksand", sans-serif !important;
  font-optical-sizing: auto;
  
  font-style: normal;
  font-synthesis: none;
  text-rendering: optimizeLegibility;

  /* color: rgba(255, 255, 255, 0.87); */
  /* background-color: #242424; */
  
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  width: 100%;
  justify-content: center ;

}
*{
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
