body {
    background-color: #f4f4f9;
    font-family: 'Roboto', sans-serif;
}

a {
    color: #2980b9;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
