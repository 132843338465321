.content-container {
    flex-wrap: nowrap;
    gap: 0;
  }
  
  .contact-info, .contact-form-container {
    flex: 1;
    height: 90vh;
    min-width: 500px; /* Ensures a minimum width for both columns */
  }
  
  .contact-info {
    border-radius: 10px;
  }
  
  .contact-form-container {
    border-left: none;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  @media (max-width: 1500px) {
    .content-container {
      flex-wrap: wrap; /* Allows wrapping when screen width is below 1500px */
      justify-content: center; /* Centers the content when it wraps */
    }
  
    .contact-info, .contact-form-container {
      flex: 0 1 100%; /* Makes each column take full width on small screens */
      height: auto; /* Adjusts height automatically when stacked */
      min-width: auto; /* Removes minimum width constraint */
    }
  }
  /* Contact.css */
.contact-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: auto;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  border-bottom-right-radius: 0;
}

.contact-details {
  padding: 10px 0;
}

.icon {
  color: #ffffff;
}

.social-icons .social-icon {
  font-size: 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.social-icons .social-icon:hover {
  color: #007bff; /* Adjust hover color */
}
