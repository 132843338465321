@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
*{
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
}
.fontfamily{
    font-family: 'Roboto Slab, serif';
}
.bgcolor{
    background-color: #EFE6E1;
    color:#222222
}
/* {Navbar.css

.navbar-container {
    margin-bottom: 110px;
    z-index: 2;
    position: sticky;
  }
  
  .navbar-toolbar {
    display: flex;
    justify-content: space-around;
  }
  
  .navbar-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .navbar-logo-img {
    width: 40px;
     Adjust the width as needed for different screen sizes 
  }
  
  .navbar-logo-text {
    font-size: 20px;
    margin: 0 10px;
  }
  
  .navbar-desktop-logo {
    width: 60px;
    /* Adjust the width as needed for different screen sizes 
  }
  
  .navbar-desktop-logo-text {
    font-size: 10px;
    margin-top: 2px;
    font-family: 'Inter', sans-serif;
  }
  
  .navbar-button {
    color: #fff;
    font-family: 'Roboto Slab', serif;
  }
  
  .navbar-divider {
    margin: 0 10px;
    color: white;
  }
  
  .drawer-container {
    text-align: center;
  }
  
  .drawer-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .drawer-logo-img {
    width: 60px;
    /* Adjust the width as needed 
  }
  
  .drawer-logo-text {
    font-size: 10px;
    margin: 10px 0;
    font-family: 'Inter', sans-serif;
  }
  
  .drawer-button {
    text-align: center;
    font-family: 'Roboto Slab', serif;
    font-weight: bold;
  }
  
  .divider-full-width {
    width: 100%;
    border-bottom: 1px solid #ddd; /* Adjust the color as needed 
  }
  } */