/* Root level variable for server-defined background color */
:root {
    --server-bg-color: #EFE6E1; /* Default fallback color */
}

html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    background-color: var(--server-bg-color);
    scroll-behavior: smooth;
}

* {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.App {
    width: 100%;
    
    text-align: center;
    margin: 0;
    padding: 0;
}

.App-header {
    background-color: var(--server-bg-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Navbarbg {
    background-color: var(--server-bg-color);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.texthead {
    font-family: 'Inter';
    font-weight: bolder;
    color: #222222;
    font-size: 2rem;
    text-align: center;
}

.image {
    background-image: url('https://www.freepik.com/free-photo/learn-german-language-online-education-concept_18129397.htm#fromView=search&page=1&position=0&uuid=a447d9e0-38ac-451f-b6c0-cead61dd13a8');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

/* Flex utilities */
.flexs {
    display: flex;
}

.flexs-col {
    display: flex;
    flex-direction: column;
}

.flexs-all-col {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.flexs-all-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

/* Hover effects */
.hovernavlink:hover {
    color: #C8A272;
    background-color: #222222;
    border-radius: 10px;
    padding: 10px;
}

.cardhover:hover {
    box-shadow: 0px 11px 16px #0000009c;
    transition: 0.5s;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .App-header {
        font-size: calc(8px + 2vmin);
    }

    .texthead {
        font-size: 1.5rem;
    }

    .fontcolorblue {
        align-items: center;
        justify-content: center;
    }

    .image {
        height: 300px;
    }
}

@media (max-width: 768px) {
    .App-logo {
        height: 30vmin;
    }

    .texthead {
        font-size: 1.2rem;
    }

    .fontcolorblue {
        flex-direction: column;
    }

    .flexs-all-row {
        flex-direction: column;
        padding: 10px;
    }

    .image {
        height: 250px;
    }
}

@media (max-width: 480px) {
    .App-header {
        font-size: calc(6px + 2vmin);
        padding: 10px;
    }

    .Navbarbg {
        padding: 5px;
        text-align: center;
        flex-direction: column;
    }

    .texthead {
        font-size: 1rem;
    }

    .image {
        height: 200px;
        width: 100%;
    }

    .cardhover:hover {
        box-shadow: none;
    }
}
/* Fine-tuning small screen layout (XS devices) */
@media (max-width: 480px) {
    .App-header {
        font-size: 12px; /* Adjust font size */
        padding: 10px;   /* Reduce padding for better spacing */
    }

    .texthead {
        font-size: 1rem;
        margin: 10px 0; /* Add space for readability */
    }

    .Navbarbg {
        padding: 5px;
    }
    
    .App {
        padding: 0 10px; /* Add small padding for better fit */
    }

    .WhatsAppIcon {
        bottom: 20px;
        right: 10px;
    }
}
